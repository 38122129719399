import React from "react"

const Collapsible = ({ summary, children }) => {
  return (
    <details
      css={theme => ({
        backgroundColor: theme.colors.grey[5],
        border: `1px solid ${theme.colors.grey[10]}`,
        borderRadius: theme.radii[3],
        padding: theme.space[7],
        paddingTop: theme.space[6],
        paddingBottom: theme.space[6],
        marginBottom: theme.space[7],
        transition: `all ${theme.transitions.speed.default} ${theme.transitions.curve.default}`,

        ">:last-child": {
          marginBottom: theme.space[0],
        },

        ">:nth-child(2)": {
          marginTop: theme.space[3],
        },

        "&[open]": {
          backgroundColor: theme.colors.white,
          border: `1px solid ${theme.colors.grey[20]}`,
          boxShadow: theme.shadows.raised,

          ">summary": {
            marginBottom: theme.space[7],
          },
        },
      })}
    >
      <summary
        css={theme => ({
          display: "list-item",
          cursor: "pointer",

          "&::marker": {
            color: theme.colors.purple[50],
          },

          ">:first-child": {
            display: "inline",
          },
        })}
      >
        {summary}
      </summary>
      {children}
    </details>
  )
}

export default Collapsible
